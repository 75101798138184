/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  Object.assign(window, wp.i18n);

  var notificationTimer;
  window.showNotification = function(type, message) {
    var icon;
    if (type === 'warning') {
      icon = '<i class="fa fa-warning warning" aria-hidden="true"></i>';
    } else if (type === 'success') {
      icon = '<i class="fa fa-check success" aria-hidden="true"></i>';
    } else if (type === 'info') {
      icon = '<i class="fa fa-info info" aria-hidden="true"></i>';
    }

    var notification = $('.full-notification');

    if (!notification.length) {
      $('body').prepend('<div class="full-notification">' + icon + ' ' + message + '</div>');
      notification = $('.full-notification');
    } else {
      notification.html(icon + ' ' + message);
    }

    notification.fadeIn(250);

    if (notificationTimer) {
      clearTimeout(notificationTimer);
    }

    notificationTimer = setTimeout(function() {
      notification.fadeOut(250);
    }, 4000);
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.topmenu a.dropdown-link').click(function(e) {
          e.preventDefault();
          var offset = $(this).offset();
          var categoryDropdown = $('.category-dropdown-' + $(this).data('category'));

          if (categoryDropdown.is(':visible')) {
            categoryDropdown.hide();
          } else {
            $('.category-dropdown').hide();
            if (/Mobi/.test(navigator.userAgent)) $('.topmenu').css('overflow', 'scroll');
            categoryDropdown.show();
          }
        });

        $('input[name=filter-category]').keyup(function(e) {
          var search = e.target.value.toLowerCase().trim();

          if (search.length < 1) {
            $(this).siblings('.chunk').show();
            $(this).parent().find('.filter-category-results').hide();
          } else {
            $(this).siblings('.chunk').hide();
            $(this).parent().find('.filter-category-results').show();

            var filtered = $(this).parent().find('ul.sub li').sort(function(a, b) {
              return ($(b).text().toLowerCase().trim()) < ($(a).text().toLowerCase().trim()) ? 1 : -1;
            }).map(function(i, elem) {
              if ($(elem).text().toLowerCase().trim().indexOf(search) !== -1)
                return '<li>' + $(elem).html() + '</li>';
            }).get().join('');

            $(this).parent().find('.filter-category-results').html('<ul>' + filtered + '</ul>');
          }
        });

        var chunkSubs = $('.category-dropdown .chunk .sub');
        $('.category-dropdown .chunk .show-more').click(function(e) {
          e.preventDefault();
          var sub = $(this).prev();

          var hideText = __('Dölj', 'piqqer') + '<i class="fa fa-angle-up" aria-hidden="true"></i></a>';
          var showText = __('Visa mer', 'piqqer') +  '<i class="fa fa-angle-down" aria-hidden="true"></i></a>';

          $(chunkSubs).not(sub).removeClass('expand');
          $(chunkSubs).next().html(showText);

          $(sub).toggleClass('expand');

          if ($(sub).hasClass('expand')) {
            $(this).html(hideText);
          } else {
            $(this).html(showText);
          }
        });

        $(document).click(function(e) {
          if ($(e.target).closest('.topmenu').length < 1) {
            $('.category-dropdown').hide();
          }
        });

        // mobile menu
        if (/Mobi/.test(navigator.userAgent)) {
          $('.topmenu .fa-bars').click(function() {
            $('.topmenu').css('overflow', 'visible');
            $('.mobile-dropdown-content').css('right', '0px');
          });
        }

        $('.category-dropdown .fa-times').click(function() {
          $(this).parent().parent().hide();
        });

        $('.mobile-dropdown-content .fa-times, .mobile-dropdown-content a').click(function() {
          $('.mobile-dropdown-content').css('right', '-999px');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'login': {
      finalize: function() {
        var loginSignup = $('#login-signup');
        var loginForm = $('#login-form');
        var navLogin = $('.login-button');
        var navSignup = $('.signup-button');
        var loginFormSubmit = loginForm.find('#login-submit');
        var loginFormSignup = loginForm.find('.signup');
        var forgotPasswordForm = $('#forgot-password');
        var resetPasswordForm = $('#reset-password');
        var resetPasswordPassword = $('#reset-password input[name="new-password"]');
        var resetPasswordSubmit = $('#reset-password #reset-password-submit');
        var forgotPasswordLink = $('#login-form .forgot-password-link');
        var forgotPasswordSubmit = $('#forgot-password #forgot-password-submit');
        var newPasswordForm = $('#activate-signup');
        var newPasswordFormButton = $('#activate-signup-submit');
        var signupFormShort = $('#signup-form-short');
        var signupFormShortButton = signupFormShort.find('button');

        signupFormShort.validate({
          rules: {
            'first_name': {
              required: true,
              minlength: 2
            },
            'last_name': {
              required: true,
              minlength: 2
            },
            'email': {
              required: true,
              email: true
            },
          },
          messages: {
            'first_name': signup.error_firstname,
            'last_name': signup.error_lastname,
            'email': signup.error_email,
          }
        });

        newPasswordForm.validate({
          rules: {
            'password': {
              required: true,
              minlength: 5
            },
            'repeat-password': {
              required: true,
              minlength: 5,
              equalTo: 'input[name=password]'
            }
          },
          messages: {
            'password': {
              required: signup.error_password_required,
              minlength: signup.error_password_minlength
            },
            'repeat-password': {
              required: signup.error_password_required,
              minlength: signup.error_password_minlength,
              equalTo: signup.error_password_repeat
            }
          }
        });

        resetPasswordForm.validate({
          rules: {
            'new-password': {
              required: true,
              minlength: 5
            },
            'repeat-new-password': {
              required: true,
              minlength: 5,
              equalTo: 'input[name="new-password"]'
            }
          },
          messages: {
            'new-password': {
              required: signup.error_password_required,
              minlength: signup.error_password_minlength
            },
            'repeat-new-password': {
              required: signup.error_password_required,
              minlength: signup.error_password_minlength,
              equalTo: signup.error_password_repeat
            },
          }
        });

        function showLoginForm() {
          forgotPasswordForm.hide();
          resetPasswordForm.hide();

          loginForm.show(0, function() {
            loginSignup.fadeIn(350);
          });
        }

        function showForgotPasswordForm() {
          loginForm.hide();

          forgotPasswordForm.fadeIn(250);
        }

        forgotPasswordLink.click(function(e) {
          e.preventDefault();

          showForgotPasswordForm();
        });

        forgotPasswordSubmit.click(function(e) {
          e.preventDefault();

          var forgotPasswordEmail = $('form#forgot-password input[name=email]').val();

          var data = {
            action: 'ajax_forgot_password',
            email: forgotPasswordEmail,
            security: $('form#forgot-password #security').val()
          };

          forgotPasswordSubmit.prop('disabled', true);
          $.post(ajax_object.ajax_url, data, function(response) {
            forgotPasswordSubmit.prop('disabled', false);

            if (response.success) {
              showNotification('success', __('Se din mail för återställning.', 'piqqer'));
            } else {
              showNotification('warning', __('Gick inte att skicka mail.', 'piqqer'));
            }
          });
        });

        resetPasswordSubmit.click(function(e) {
          e.preventDefault();

          resetPasswordSubmit.prop('disabled', true);

          if(resetPasswordForm.valid()) {
            var data = {
              action: 'ajax_reset_password',
              uuid: resetPasswordForm.find('#reset-password-uuid').val(),
              password: resetPasswordPassword.val(),
              security: $('form#reset-password #security').val()
            };

            $.post(ajax_object.ajax_url, data, function(response) {
              resetPasswordSubmit.prop('disabled', false);

              if (response.success) {
                showNotification('success', __('Lösenordet har återställts.', 'piqqer'));
              } else {
                showNotification('warning', __('Gick inte att återställa lösenordet.', 'piqqer'));
              }

              hideLoginSignup();
            });
          }
        });

        navLogin.click(function(e) {
          e.preventDefault();

          showLoginForm();
        });

        navSignup.click(function(e) {
          e.preventDefault();

          if (document.referrer === window.location.href) {
            $([document.documentElement, document.body]).animate({
              scrollTop: $("#signup-form-short").offset().top - 100
            }, 1000);
          } else {
            window.location.href = window.location.origin + '?show_signup';
          }
        });

        function hideLoginSignup() {
          loginSignup.fadeOut(350, function() {
            loginForm.hide();
            resetPasswordForm.hide();
            forgotPasswordForm.hide();
          });
        }

        $(document).click(function(e) {
          if (
              !navLogin.add(navSignup).is(e.target) && navLogin.add(navSignup).has(e.target).length === 0 &&
              !loginForm.add(forgotPasswordForm).is(e.target) && loginForm.add(forgotPasswordForm).has(e.target).length === 0 &&
              !loginForm.add(resetPasswordForm).is(e.target) && loginForm.add(resetPasswordForm).has(e.target).length === 0 &&
              !loginForm.add(newPasswordForm).is(e.target) && loginForm.add(newPasswordForm).has(e.target).length === 0
            ) hideLoginSignup();
        });

        var loginFormEmail = loginForm.find('input[name="login-email"]');
        var loginFormPassword = loginForm.find('input[name="login-password"]');
        var loginFormSubmitText = loginFormSubmit.text();

        loginFormSubmit.click(function(e) {
          e.preventDefault();

          loginFormSignup.hide();

          $(this).html('<i class="fa fa-spin fa-circle-o-notch" aria-hidden="true"></i>');
          $(this).addClass('loading');

          var loginSignupRedirect = loginSignup.find('input[name="login-redirect"]').val();
          var loginSignupOrgID = loginSignup.find('input[name="login-orgid"]').val();
          var loginInvite = loginSignup.find('input[name="login-invite"]').val();

          var data = {
            'action': 'ajax_login',
            'username': loginSignup.find('input[name="login-email"]').val(),
            'password': loginSignup.find('input[name="login-password"]').val(),
            'security': loginSignup.find('input[name="security"]').val()
          };

          if (loginSignupRedirect.length > 1) data.redirect = loginSignupRedirect;
          if (loginSignupOrgID.length > 1) data.orgID = loginSignupOrgID;
          if (loginInvite.length > 1) data.invite = loginInvite;

          $.post(ajax_object.ajax_url, data, function(response) {
            loginFormSubmit.removeClass('loading');

            if (response.success) {
              loginFormSubmit.removeClass('error').addClass('success');
              loginFormSubmit.html('<i class="fa fa-check" aria-hidden="true"></i>');

              setTimeout(function() {
                $('html').fadeOut(500, function() {
                  location.replace(response.data);
                });
              }, 300);
            } else {
              loginFormSubmit.removeClass('success').addClass('error');
              loginFormSubmit.html('<i class="fa fa-exclamation-circle" aria-hidden="true"></i>');

              loginFormEmail.one('focus', function() {
                loginFormSubmit.removeClass('error').html(loginFormSubmitText);
              });

              loginFormPassword.one('focus', function() {
                loginFormSubmit.removeClass('error').html(loginFormSubmitText);
              });
            }
          });
        });

        var signupManufacturer = $('.reseller-manufacturer-signup .signup-form.manufacturer');
        var signupReseller = $('.reseller-manufacturer-signup .signup-form.reseller');
        var signupEnterprise = $('.reseller-manufacturer-signup .signup-form.enterprise');
        
        function signupResellerManufacturer(type, companyName, firstName, lastName, email, phone) {
          var uk = $('body').hasClass('uk');

          if (companyName === '' || firstName === '' || lastName === '' || email === '') {
            if (uk) return vex.dialog.alert('Error! Some fields are not valid.');

            return vex.dialog.alert('Fel! Alla obligatoriska fält måste fyllas i.');
          }

          $.post(adminAjaxURL, {
            action: 'ajax_send_email',
            type: type,
            companyName: companyName,
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            uk: uk
          }, function(response) {
              $('form.signup-form')[0].reset();
              return vex.dialog.alert(response.data);
          });
        }
      
        signupReseller.find('#submit_reseller').click(function(e) {
          e.preventDefault();
      
          var companyName = signupReseller.find('#reseller_companyName').val();
          var firstName = signupReseller.find('#reseller_firstName').val();
          var lastName = signupReseller.find('#reseller_lastName').val();
          var email = signupReseller.find('#reseller_email').val();
          var phone = signupReseller.find('#reseller_phone').val();
      
          signupResellerManufacturer('Reseller', companyName, firstName, lastName, email, phone);
        });
      
        signupManufacturer.find('#submit_manufacturer').click(function(e) {
          e.preventDefault();
      
          var companyName = signupManufacturer.find('#manufacturer_companyName').val();
          var firstName = signupManufacturer.find('#manufacturer_firstName').val();
          var lastName = signupManufacturer.find('#manufacturer_lastName').val();
          var email = signupManufacturer.find('#manufacturer_email').val();
          var phone = signupManufacturer.find('#manufacturer_phone').val();
      
          signupResellerManufacturer('Manufacturer', companyName, firstName, lastName, email, phone);
        });

        signupEnterprise.find('#submit_enterprise').click(function(e) {
          e.preventDefault();
      
          var companyName = signupEnterprise.find('#manufacturer_companyName').val();
          var firstName = signupEnterprise.find('#manufacturer_firstName').val();
          var lastName = signupEnterprise.find('#manufacturer_lastName').val();
          var email = signupEnterprise.find('#manufacturer_email').val();
          var phone = signupEnterprise.find('#manufacturer_phone').val();
      
          signupResellerManufacturer('Enterprise', companyName, firstName, lastName, email, phone);
        });


        var faqQuestions = $('.faq-questions li');

        faqQuestions.click(function() {
          $(this).find('.answer').toggle();
        });
        
        var mobile_menu_button = $('#mobile-menu-button');
        var header_container = $('#header-container');
        var body_login = $('body.login');

        function toggleMenu() {
          if (mobile_menu_button.hasClass('fa-bars')) {
            mobile_menu_button.removeClass('fa-bars');
            mobile_menu_button.addClass('fa-times');
          } else {
            mobile_menu_button.removeClass('fa-times');
            mobile_menu_button.addClass('fa-bars');
          }
          
          body_login.toggleClass('show-menu');
          header_container.toggleClass('show-menu');
        }

        mobile_menu_button.click(function() {            
          toggleMenu();
        });

        header_container.find('#menu a').click(function() {
          toggleMenu();
        });

        signupFormShortButton.click(function(e) {
          e.preventDefault();

          if (!signupFormShort.valid()) return false;

          var first_name = signupFormShort.find('input[name=first_name]').val();
          var last_name = signupFormShort.find('input[name=last_name]').val();
          var email = signupFormShort.find('input[name=email]').val();

          if (signupFormShort.find('#accept').prop('checked') !== true) {
            return showNotification('warning', __('Du måste acceptera integritetspolicyn för att skapa ett konto.', 'piqqer'));
          }

         signupFormShortButton.attr('disabled', true);

          $.post(adminAjaxURL, {
            action: 'create_new_user',
            security: $('#signup-form-nonce').val(),
            first_name: first_name,
            last_name: last_name,
            email: email
          }, function(response) {
            signupFormShortButton.removeAttr('disabled');

            if (response.success) {
              return showNotification('success', response.data);
            } else {
              return showNotification('warning', response.data);
            }
          });
        });

        newPasswordFormButton.click(function(e) {
          e.preventDefault();

          var newPassword = newPasswordForm.find('input[name=password]').val();
          var repeatPassword = newPasswordForm.find('input[name=repeat-password]').val();

          if (newPassword !== repeatPassword) return showNotification('warning', __('Lösenordet stämmer inte överens.', 'piqqer'));

          newPasswordFormButton.attr('disabled', true);

          $.post(adminAjaxURL, {
            action: 'activate_new_user',
            security: newPasswordForm.find('input[name=security]').val(),
            user_hash: newPasswordForm.find('input[name=user-hash]').val(),
            password: newPassword
          }, function(response) {
            newPasswordFormButton.removeAttr('disabled');

            if (response.success) {
              window.location.reload()
            } else {
              return showNotification('warning', response.data);
            }
          });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_welcome': {
      init: function() {
      }
    },
    'single_piqqer_resellers': {
      finalize: function() {
        $('#review-open-form-btn').click(function() {
          $('#review_form_wrapper').toggle();
        });

        $('#customer-wants-reseller').click(function() {
          $.post(ajax_object.ajax_url, {
            action: 'customer_wants_reseller',
            reseller_id: $(this).data('reseller')
          }, function(response) {
            if (response.success) {
              window.showNotification('success', response.data);
            } else {
              window.showNotification('info', response.data);
            }
          })
        });
      }
    },
    'single_product': {
      init: function() {
        $('ul.product-data-tabs li').click(function() {
          var tab_id = $(this).data('tab');

          $('ul.product-data-tabs li').removeClass('current');
          $('.tab-content').removeClass('current');

          $(this).addClass('current');
          $('#' + tab_id).addClass('current');
        });

        // single product full description toggle
        $('.woo-single-product .full-description-toggle').click(function(e) {
          e.preventDefault();

          var $description = $('.woo-single-product .description');
          var $toggle = $(this);

          if ($description.hasClass('show-full')) {
            $description.removeClass('show-full');
            $toggle.html(__('Visa full beskrivning', 'piqqer'));
          } else {
            $description.addClass('show-full');
            $toggle.html(__('Dölj full beskrivning', 'piqqer'));
          }
        });

        $('.add-review').click(function() {
          $('#review_form').toggle();
        });
      }
    },
    'dashboard': {
      init: function() {
        var budget = 45;

        var ctx = document.getElementById("canvas").getContext('2d');
        var myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
                datasets: [
                  {
                    label: 'Driftbudget MSEK',
                    data: [budget, budget, budget, budget, budget, budget, budget * 0.8, budget, budget, budget, budget, budget],
                    backgroundColor: 'rgba(255, 99, 132, 1)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    fill: false
                  },
                  {
                    label: 'Utfall MSEK',
                    data: [budget * 0.95, budget * 0.90, budget * 0.88, budget * 0.91, budget * 0.75, budget * 0.7, budget * 0.5, budget * 0.6, budget * 0.8, budget * 0.9, budget, budget * 1.1],
                    backgroundColor: 'rgb(66, 134, 244)',
                    borderColor: 'rgb(66, 134, 244)',
                    fill: false
                  }
              ]
            },
            options: {
              responsive: true,

              scales: {
                  xAxes: [{
                      display: true,
                  }],
                  yAxes: [{
                      display: true,
                      ticks: {
                        suggestedMax: 50,
                      }
                  }]
              }
            }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
