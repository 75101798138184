(function($) {
  Object.assign(window, wp.i18n);

  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;

        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);

      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  // logic for searchbar
  var searchbar = $('.searchbar');
  var searchbarInput = $('.searchbar input[name=s]');
  var searchbarResults = $('.searchbar-results');
  var searchbarIcon = $('.searchbar .search-icon');
  var search = debounce(function() {
    var value = searchbarInput.val();

    if (value.length < 1 || value === '' || value.trim().length === 0) {
      searchbarResults.hide();
      searchbarResults.empty();

      return false;
    }

    var data = {
      'action': 'woo_ajax_search',
      's': value
    };

    searchbarResults.hide();
    searchbarIcon.removeClass('fa-search').addClass('fa-circle-o-notch fa-spin');
    searchbarResults.empty();
    $.post(ajax_object.ajax_url, data, function(response) {
      searchbarResults.empty();

      searchbarIcon.removeClass('fa-circle-o-notch fa-spin').addClass('fa-search');

      // add categories to results
      if (response.data.categories.length > 0) {
        var categories = '<div class="categories"><span class="search-title">' + __('Kategorier', 'piqqer') + '</span><ul>';

        response.data.categories.forEach(function(category) {
          categories += '<li class="category">' +
            '<a href="' + category.link + '">' +
            category.name + '</a></li>';
        });

        categories += '</ul></div>';

        searchbarResults.append(categories);
      }

      // add resellers to results
      if (response.data.resellers.length > 0) {
        var resellers = '<div class="resellers"><span class="search-title">' + __('Återförsäljare', 'piqqer') + '</span> <ul>';

        response.data.resellers.forEach(function(reseller) {
          resellers += '<li class="reseller">' +
            '<a href="' + reseller.link + '">' +
            '<img src="' + reseller.logo + '" class="reseller-logo" />' +
            '</a></li>';
        });

        resellers += '</ul></div>';

        searchbarResults.append(resellers);
      }

      // add manufacturers to results
      if (response.data.manufacturers.length > 0) {
        var manufacturers = '<div class="manufacturers"><span class="search-title">' + __('Tillverkare', 'piqqer') + '</span> <ul>';

        response.data.manufacturers.forEach(function(manufacturer) {
          manufacturers += '<li class="manufacturer">' +
            '<a href="' + manufacturer.link + '">' +
            '<img src="' + manufacturer.logo + '" class="manufacturer-logo" />' +
            '</a></li>';
        });

        manufacturers += '</ul></div>';

        searchbarResults.append(manufacturers);
      }

      if (response.data.products.length > 0) searchbarResults.append('<span class="search-title">' + __('Produkter', 'piqqer') + '</span><ul class="searchbar-results-products"></ul>');

      var image, price = '';
      response.data.products.forEach(function(product) {
        if (product.product_image) {
          image = '<img src="' + product.product_image + '" />';
        } else {
          image = '';
        }

        if (product.product_price) {
          product.product_price = parseInt(product.product_price).toLocaleString('sv-SE');
          price = '<span class="price">' + sprintf(__('från %s', 'piqqer'), product.product_price_with_currency) + '</span>';
        } else {
          price = '';
        }

        if (product.product_price_excl_vat) {
          product.product_price_excl_vat = parseInt(product.product_price_excl_vat).toLocaleString('sv-SE');
          price_excl_vat = '<span class="price">' + sprintf(__('från %s', 'piqqer'), product.product_price_excl_vat_with_currency) + ' ' + __('exkl. moms', 'piqqer') + '</span>';
        } else {
          price_excl_vat = '<span class="price">' + __('Inget pris', 'piqqer') + '</span>';
        }

        $('.searchbar-results-products').append(
          '<li class="woocommerce">' +
            '<a class="row" href="' + product.product_url + '">' +
              '<div class="col-md-1 col-xs-12 image">' + image + '</div>' +
              '<div class="col-md-6 col-xs-12 title">' +
                product.product_title +
                '<div class="sku">' + __('Art.nr', 'piqqer') + ' ' + product.product_sku + '</div>' +
                '<div class="category">' + product.product_categories.join(', ') + '</div>' +
              '</div>' +
              '<div class="col-md-2 col-xs-12 rating">' + product.product_rating + '</div>' +
              (
                piqqer.show_prices_incl_vat ?
                  '<div class="col-md-3 col-xs-12 price">' + price + ' ' + __('inkl. moms', 'piqqer') + '</div>' :
                  '<div class="col-md-3 col-xs-12 price">' + price_excl_vat
              ) +
              '</div>' +
            '</a>' +
          '</li>'
        );

        // prevent loosing focus
        searchbarResults.find('a').mousedown(function(e) {
          e.preventDefault();
        });
      });

      if (response.data.products.length > 0 || response.data.categories.length > 0 || response.data.resellers.length > 0 || response.data.manufacturers.length > 0) {
        searchbarResults.show();
      } else {
        searchbarResults.hide();
      }
    });
  }, 500);
  searchbarInput.on('input search', search);

  // disable search if no search word
  searchbar.submit(function() {
    if (searchbarInput.val().length === 0) {
      return false;
    }

    return true;
  });

  searchbarInput.focusout(function() {
    $('.fade-overlay').fadeOut(350, function() {
      searchbar.removeAttr('style');
    });
  });

  searchbarInput.focusin(function() {
    if (!searchbarResults.is(':empty')) {
      searchbarResults.show();
    }
  });

  // close searchbar results on click outside
  $(document).click(function(e) {
    if ($(e.target).closest('.searchbar').length < 1 && $(e.target).closest('.topmenu').length < 1) {
      searchbarResults.hide();
      $('.fade-overlay').fadeOut(350);
    }
  });

  // product list logic
  // the product list logic is wrapped in a function so it can be re-called everytime product list DOM is updated (e.g facetwp reload)
  function productListLogic() {
    var hoverTime;
    $('.product-list-table .product-list-table-title-link, .product-resellers-count').mouseenter(function() {
      var productHover = $(this);

      hoverTime = setTimeout(function() {
        $('.product-hover').remove();

        var image = productHover.parent().parent().find('img').attr('src');
        var price = productHover.parent().parent().find('.product-list-table-price-amount').html();
        var stock = productHover.parent().parent().find('.product-list-table-stock').html();
        var link = productHover.parent().find('a').attr('href');
        var title = productHover.text();
        var product_id = productHover.parent().parent().data('product');

        var resellersData = '';
        $.ajax({
          url: ajax_object.ajax_url,
          data: {
            action: 'get_resellers_of_product',
            product_id: product_id
          },
          type: 'POST',
          success: function(data) {
            if (data.length > 0) {
              var resellers = [];

              data.forEach(function(reseller) {
                if (typeof resellers[reseller.reseller_id] === 'undefined' || resellers[reseller.reseller_id].price > reseller.price)
                  resellers[reseller.reseller_id] = reseller;
              });

              resellers.forEach(function(reseller) {
                var ratingPercent = parseInt(reseller.rating) / 5 * 100;
                if (reseller.rating === '' || !reseller.rating) ratingPercent = 0;

                var stock;
                if (reseller.stock === 0) {
                  stock = __('Ej i lager', 'piqqer');
                } else {
                  stock = __('I lager', 'piqqer');
                }

                resellersData += '<tr>' +
                                  '<td>' +
                                    reseller.name +
                                    '<div class="star-rating" title="Betygsatt ' + reseller.rating + ' av 5"><span style="width:' + ratingPercent + '%"><strong class="rating">' + reseller.rating + '</strong> av 5</span></div>' +
                                  '</td>' +
                                  '<td>' + (piqqer.show_prices_incl_vat ? reseller.price : reseller.price_excl_vat) + '</td>' +
                                  '<td>' + stock + '</td>' +
                                '</tr>';
              });
            } else {
              resellersData = __('Inga återförsäljare erbjuder den här produkten', 'piqqer');
            }

            productHover.parent().parent().next('.product-hover').find('.product-hover-resellers').html('<table>' + resellersData + '</table>');
          }
        });

        var hoverElement;
        hoverElement = '<tr class="product-hover">' +
                          '<td colspan="6">' +
                            '<div class="product-hover-image box">' +
                              '<a href="' + link + '">' +
                                '<img src="' + image + '"/>' +
                              '</a>' +
                            '</div>' +
                            '<div class="product-hover-resellers">' +
                            '<i class="fa fa-spin fa-circle-o-notch"></i>' +
                            '</div>' +
                          '</td>' +
                        '</tr>';

        productHover.closest('tr').after(hoverElement);
      }, 750);
    }).mouseleave(function() {
      clearTimeout(hoverTime);
    });

    // compare logic
    var compareBtn = $('.compare-button');
    var addtolistBtn = $('.add-to-list-button');
    var removeBtn = $('.remove-from-list-button');

    var compareBtnURL = compareBtn.parent().attr('href');
    var removeBtnURL = removeBtn.parent().attr('href');

    var productListChecked;

    function toggleCompareListBtns() {
      // redefine this to get the new ones after facetwp adds to dom
      var compareBtn = $('.compare-button');
      var addtolistBtn = $('.add-to-list-button');
      var removeBtn = $('.remove-from-list-button');

      productListChecked = $.map($('.product-list-table-checkbox input[type=checkbox]'), function(product, i) {
        if (product.checked) return product.value;
      });

      productListChecked.length > 1 ? compareBtn.prop('disabled', false) : compareBtn.prop('disabled', true);
      productListChecked.length > 0 ? addtolistBtn.prop('disabled', false) : addtolistBtn.prop('disabled', true);
      productListChecked.length > 0 ? removeBtn.prop('disabled', false) : removeBtn.prop('disabled', true);
    }
    $('.product-list-table-checkbox input[type=checkbox]').click(function() {
      var checkbox_all = $('.product-table thead input[type=checkbox]');

      if (checkbox_all.prop('checked')) {
        checkbox_all.prop('checked', false);
      }

      toggleCompareListBtns();
    });

    // product list select all logic
    $('.product-table thead input[type=checkbox]').click(function() {
      var checkboxes = $('.product-list-table-checkbox input[type=checkbox]');

      if ($(this).prop('checked')) {
        checkboxes.prop('checked', true);
      } else {
        checkboxes.prop('checked', false);
      }

      toggleCompareListBtns();
    });

    compareBtn.click(function(e) {
      e.preventDefault();

      window.location.href = compareBtnURL + '?products=' + productListChecked.toString();
    });

    removeBtn.click(function(e) {
      e.preventDefault();

      window.location.href = removeBtnURL + '?remove=' + productListChecked.toString();
    });

    // add to list archive
    addtolistBtn.click(function(e) {
      addtolistBtn.prop('disabled', true);

      $.ajax({
        method: 'POST',
        url: ajax_object.ajax_url,
        data: {
          action: 'add_to_list',
          products: productListChecked
        },
        success: function(response) {
          addtolistBtn.prop('disabled', false);

          if (response.data.products.length > 0)
            showNotification('success', sprintf(__('Du har lagt till %dst produkter i listan', 'piqqer'), productListChecked.length));
        }
      });
    });
  }

  productListLogic();

  // add to list single product
  var addtolistBtnSingle = $('.add-to-list-single');

  addtolistBtnSingle.click(function(e) {
    addtolistBtnSingle.prop('disabled', true);

    $.ajax({
      method: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'add_to_list',
        products: $('.woo-single-product').data('product-id')
      },
      success: function(response) {
        addtolistBtnSingle.prop('disabled', false);

        if (response.data.products.length > 0)
          showNotification('success', __('Du har lagt till produkten i listan', 'piqqer'));
      }
    });
  });


  // checkout logic

  // checks if user has selected payment options on all orders
  $('body.woocommerce-checkout #checkout-button').click(function(e) {
    e.preventDefault();
    var all_selected = true;

    $('.reseller-payment-method').each(function(i, payment_method) {
      var checked_radio_buttons = $(payment_method).find('input[type=radio]:checked');

      // check if selected stripe card
      checked_radio_buttons.each(function(i, checked_radio_button) {
        if ($(checked_radio_button).val() === 'stripe') {
          var card_value = $(payment_method).next().find('.stripe_select_cards_dropdown').val();

          if (card_value && isNaN(card_value)) {
            all_selected = false;
          }
        }
      });

      if (!checked_radio_buttons.length) {
        all_selected = false;
      }
    });

    if (all_selected) {
      window.location.href = this.href;
    } else {
      return showNotification('warning', __('Du måste välja betalningssätt för alla ordrar.', 'piqqer'));
    }
  });

  // logic for account bar
  var accountBarOrg = $('#account-bar .organization');
  var accountBarOrgList = $('#account-bar .organization ul');
  var accountBarUser = $('#account-bar .user');
  var accountBarUserList = $('#account-bar .user ul');

  accountBarOrg.hover(function() {
    accountBarOrgList.toggle();
  });

  accountBarUser.hover(function() {
    accountBarUserList.toggle();
  });

  $(document).on('facetwp-loaded', function() {
    var selectedManufacturers = '';
    $.each($('.facetwp-facet-manufacturers .facetwp-checkbox.checked'), function(index, item) {
      selectedManufacturers += '<div class="selected">' + $(item).html() + '</div>';
    });

    $('.facets-manufacturers-selected').html(selectedManufacturers);

    var selectedResellers = '';
    $.each($('.facetwp-facet-resellers .facetwp-checkbox.checked'), function(index, item) {
      selectedResellers += '<div class="selected">' + $(item).html() + '</div>';
    });

    $('.facets-resellers-selected').html(selectedResellers);

    productListLogic();
  });
})(jQuery);
