(function($) {
  Object.assign(window, wp.i18n);

  $(document).ready(function($) {

    if ($('body').hasClass('settings-account')) {
      var settingsForm = $('form#settings-account');
      var email = $('form#settings-account input#email');
      var firstName = $('form#settings-account input#firstname');
      var lastName = $('form#settings-account input#lastname');
      var newPassword = $('form#settings-account input#new-password');
      var submitButton = $('form#settings-account #settings-account-submit');
      var security = $('form#settings-account #security');

      var validate = { rules: {}, messages: {} };

      validate.rules['firstname'] = {
        minlength: 2,
        required: true
      };

      validate.rules['lastname'] = {
        minlength: 2,
        required: true
      };

      validate.rules['email'] = {
        email: true,
        required: true
      };

      validate.rules['new-password'] = {
        minlength: 5
      };

      validate.rules['repeat-new-password'] = {
        equalTo: 'input[name="new-password"]'
      };

      validate.messages = {
        'firstname': __('Du måste fylla i förnamn.', 'piqqer'),
        'lastname': __('Du måste fylla i efternamn.', 'piqqer'),
        'email': __('Du måste fylla i din email.', 'piqqer'),
        'new-password': {
          minlength: __('Lösenordet måste vara minst 5 tecken.', 'piqqer')
        },
        'repeat-new-password': {
          minlength: __('Lösenordet måste vara minst 5 tecken.', 'piqqer'),
          equalTo: __('Lösenorden måste stämma överens.', 'piqqer')
        }
      };

      settingsForm.validate(validate);

      submitButton.click(function(e) {
        e.preventDefault();

        if (settingsForm.valid()) {
          var data = {
            action: 'save_user_settings',
            email: email.val(),
            firstName: firstName.val(),
            lastName: lastName.val(),
            password: newPassword.val(),
            security: security.val()
          };

          var submitButtonText = submitButton.html();
          submitButton.html('<i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>');

          $.post(ajax_object.ajax_url, data, function(response) {
            if (response.success) {
              showNotification('success', response.data);
            } else {
              showNotification('warning', response.data);
            }
            submitButton.html(submitButtonText);
          });
        }
      });
    }
  });
})(jQuery);
