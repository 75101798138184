(function($) {
  if (!$('body').hasClass('woocommerce-cart')) return;

  const updateCartBtn = $('.cart-update-button');
  updateCartBtn.click(function() {
    const items = $('.cart-table tr[data-item-id]');
    const cart = [];

    items.each(function() {
      const itemId = $(this).data('item-id');
      const quantity = $(this).find('input[name=quantity]').val();

      cart.push({
        itemId: itemId,
        quantity: quantity
      });
    });

    updateCartBtn.prop('disabled', true);
    $.post(window.ajax_object.ajax_url, {
      action: 'update_cart',
      cart: JSON.stringify(cart)
    })
    .done(function(response) {
      if (response.success) {
        window.location.reload();
      } else {
        showNotification('warning', response.data);

        // reset quantity to original
        items.each(function() {
          const originalQuantity = $(this).data('original-quantity');
          const inputQuantity = $(this).find('input[name=quantity]');

          inputQuantity.val(originalQuantity);
        });

        updateCartBtn.prop('disabled', false);
      }
    })
    .fail(function() {
      updateCartBtn.prop('disabled', false);
    });
  });
})(jQuery);